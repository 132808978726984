<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-sm">
        <q-input
          v-model="bundleAspek.aspek"
          outlined
          label="Aspek"
          readonly
        ></q-input>
        <q-input
          outlined
          v-model="bundleAspek.nama"
          label="Nama Penilaian"
        ></q-input>
        <q-input
          outlined
          type="number"
          v-model="bundleAspek.target"
          label="Target"
        ></q-input>
        <q-input
          outlined
          class="q-mt-md"
          type="number"
          @input="getBatas"
          v-model="bundleAspek.kkm"
          label="KKM"
        ></q-input>
        <p class="q-mb-none q-pl-md text-grey-7">Batas Nilai</p>
        <div class="row justify-between q-pt-none">
          <q-input
            outlined
            type="number"
            v-model="bundleAspek.batas_a"
            label="M"
            dense
            style="width:24%"
          ></q-input>
          <q-input
            outlined
            type="number"
            v-model="bundleAspek.batas_b"
            label="B"
            dense
            style="width:24%"
          ></q-input>
          <q-input
            outlined
            type="number"
            v-model="bundleAspek.batas_c"
            label="MBK"
            dense
            style="width:24%"
          ></q-input>
          <q-input
            outlined
            type="number"
            v-model="bundleAspek.batas_d"
            label="MB"
            dense
            style="width:24%"
          ></q-input>
          <a class="text-caption text-red q-pl-sm" style="width:100%"
            >*batas bawah pada nilai kualitatif</a
          >
        </div>
      </q-card-section>
      <q-card-section class="q-my-lg">
        <q-markup-table dense flat bordered>
          <tbody class="text-caption text-grey">
            <tr>
              <td>M</td>
              <td>:</td>
              <td>Membudaya</td>
            </tr>
            <tr>
              <td>B</td>
              <td>:</td>
              <td>Berkembang</td>
            </tr>
            <tr>
              <td>MBK</td>
              <td>:</td>
              <td>Mulai Berkembang</td>
            </tr>
            <tr>
              <td>MB</td>
              <td>:</td>
              <td>Membutuhkan Bimbingan</td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn
          unelevated
          v-if="idAspek < 0"
          color="primary"
          :disable="bundleAspek.nama == ''"
          @click="createNilai()"
          >Tambah</q-btn
        >
        <q-btn
          unelevated
          v-else
          color="primary"
          :disable="bundleAspek.nama == ''"
          @click="editNilai()"
          >Edit</q-btn
        >
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["idAspek", "aspek"],
  data() {
    return {
      bundleAspek: {
        nama: "",
        aspek: "",
        kkm: 0,
        target: 0,
        batas_a: 75,
        batas_b: 50,
        batas_c: 25,
        batas_d: 0,
      },
    };
  },
  mounted() {
    if (this.idAspek > 0) {
      this.getAspekDetail();
    }
    this.bundleAspek.aspek = this.aspek;
  },
  methods: {
    getBatas() {
      let tempMapel = JSON.parse(JSON.stringify(this.bundleAspek));
      let patok = 100 - parseFloat(tempMapel.kkm);
      let interval = patok / 3;
      let curNilai = parseFloat(tempMapel.kkm);
      tempMapel.batas_d = 0;
      tempMapel.batas_c = curNilai;
      curNilai = curNilai + interval;
      tempMapel.batas_b = Math.floor(curNilai);
      curNilai = curNilai + interval;
      tempMapel.batas_a = Math.floor(curNilai);
      this.bundleAspek = tempMapel;
    },
    getAspekDetail() {
      this.$http
        .get("/pengaturan/aspek/getdetail/" + this.idAspek)
        .then((resp) => {
          this.bundleAspek = resp.data;
          this.bundleAspek.aspek = resp.data.kelompok;
        });
    },
    createNilai() {
      this.bundleAspek.jenjang = localStorage.getItem("jenjang");
      this.$http
        .post("/pengaturan/aspek/tambah", this.bundleAspek)
        .then((resp) => {
          this.$q.notify({
            message: "Penilaian Berhasil Disimpan!",
            color: "positive",
          });
          this.onOKClick();
        });
    },
    editNilai() {
      this.$http
        .put("/pengaturan/aspek/edit/" + this.idAspek, this.bundleAspek)
        .then((resp) => {
          this.$q.notify({
            message: "Perubahan Berhasil Disimpan!",
            color: "positive",
          });
          this.onOKClick();
        });
    },
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      let data = {
        aspek: this.bundleAspek.aspek,
      };
      this.$emit("ok", data);
      this.hide();
    },
    onCancelClick() {
      this.hide();
    },
  },
};
</script>
