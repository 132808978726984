<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-select
            :options="listAspek"
            v-model="selAspek"
            outlined
            label="Aspek"
            style="min-width: 200px;"
            @input="getAspekList()"
          ></q-select>
          <q-btn
            icon="add"
            color="primary"
            label="Tambah Penilaian"
            v-can="['WAKA']"
            :disable="selAspek == ''"
            @click="showDialogEdit(-1)"
          ></q-btn>
        </div>
        <q-input outlined label="Cari Penilaian" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>No</th>
            <th>Penilaian</th>
            <th>KKM</th>
            <th style="width:20px;"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-if="selAspek == ''">
            <td class="text-center" colspan="4">
              Silahkan Pilih Aspek Terlebih Dahulu
            </td>
          </tr>
          <tr v-else-if="penilaian.length == 0">
            <td class="text-center" colspan="4">
              Belum Ada Penilaian di Aspek {{ selAspek }}
            </td>
          </tr>
          <tr v-for="(val, i) in filAspek" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama }}</td>
            <td>{{ val.kkm }}</td>
            <td>
              <q-btn
                flat
                dense
                icon="edit"
                color="secondary"
                v-can="['WAKA']"
                @click="showDialogEdit(val.id)"
              ></q-btn>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
  </div>
</template>

<script>
import DialogPengaturanAspek from "@/components/DialogPengaturanAspek";
export default {
  data() {
    return {
      searchTerm: "",
      listAspek: [
        "IBADAH",
        "DISIPLIN",
        "JUJUR",
        "MENGHARGAI ORANG",
        "5S",
        "HIDUP SEHAT",
        "TANGGUNG JAWAB",
        "KEBERSIHAN",
        "BAHASA ASING",
      ],
      penilaian: [],
      selAspek: "",
    };
  },
  computed: {
    filAspek() {
      if (this.searchTerm == "") return this.penilaian;
      else {
        return this.penilaian.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  mounted() {},
  methods: {
    async getAspekList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengaturan/aspek/getlist/${this.selAspek}/${localStorage.getItem(
          "jenjang"
        )} `
      );

      this.penilaian = resp.data;
      this.$q.loading.hide();
    },
    showDialogEdit(id) {
      this.$q
        .dialog({
          component: DialogPengaturanAspek,
          parent: this,
          idAspek: id,
          aspek: this.selAspek,
        })
        .onOk((resp) => {
          this.selAspek = resp.aspek;
          this.getAspekList();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
